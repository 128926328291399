<template>
  <v-container>
    <!-- TITULO -->
    <!-- <v-row v-if="!secondRow">
      <v-col>
        <h1 class="mb-5" v-if="item.desc != 'owner'">Dados:</h1>

        <h1 v-else>OWNER:</h1>
      </v-col>
    </v-row> -->

    <!-- CONTENT -->
    <div v-for="detail in family" :key="`family-${detail}`">
      <v-row class="mb-4" v-if="titleFamilyDisplay(detail)">
        <v-col cols="12">
          <h3 class="font-weight-light">{{ getFamilyName(detail - 1) }}:</h3>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!-- CONTENT -->
      <v-row>
        <v-col
          v-for="(input, i) in content.filter((e) => e.family == detail)"
          :cols="input.cols"
          :key="i"
          class="col-padding"
        >
          <v-card flat>
            <v-card-text class="inputs-container">
              <!-- TEXT FIELD -->

              <v-text-field
                :disabled="input.title == 'outro_banco' ? disabledBank : false"
                v-if="
                  input.type === 'text' && displayText(item.desc, input.title)
                "
                outlined
                :label="
                  input.title
                    .replace(/___/g, '/')
                    .replace(/__/g, '-')
                    .replace(/_/g, ' ')
                "
                :value="input.value"
                v-model="input.value"
                :hint="getHintText(input.title)"
                @change="getTheValue(input.title, input.value, item.desc)"
                :readonly="input.title == 'idade'"
                :background-color="
                  getBackgroundColor(item.desc, input.title)
                    ? color
                    : getBackgroundColorPriority(item.desc, input.title)
                    ? colorPriority
                    : ''
                "
              ></v-text-field>

              <!-- SELECTED -->
              <v-select
                outlined
                v-else-if="
                  input.type === 'select' && displayText(item.desc, input.title)
                "
                :items="input.options"
                :label="
                  input.title
                    .replace(/___/g, '/')
                    .replace(/__/g, '-')
                    .replace(/_/g, ' ')
                "
                :value="input.value"
                v-model="input.value"
                @change="
                  getTheValue(input.title, input.value, item.desc);
                  input.title == 'banco' ? selectedBank(input.value) : '';
                "
                clearable
              ></v-select>

              <!-- TEXTAREA -->
              <v-textarea
                v-else-if="
                  input.type === 'textarea' &&
                  displayText(item.desc, input.title)
                "
                outlined
                :label="
                  input.title
                    .replace(/___/g, '/')
                    .replace(/__/g, '-')
                    .replace(/_/g, ' ')
                "
                :value="input.value"
                v-model="input.value"
                @change="getTheValue(input.title, input.value, 'obs')"
              ></v-textarea>

              <template v-else-if="input.type === 'resp'" flat>
                <Responsabilities
                  :resps="input.value"
                  @update-resps="updateResps"
                />
              </template>

              <!-- SIMPLE TAB - SECOND ROW -->
              <template v-else-if="input.type === 'table' && secondRow" flat>
                <div class="pb-5 mt-5">
                  <SimpleTab
                    :data="input.value"
                    :input="true"
                    :headers="headers"
                  ></SimpleTab>
                </div>
              </template>

              <!-- DATE PICKER -->

              <v-dialog
                v-else-if="
                  input.type === 'datePicker' &&
                  displayText(item.desc, input.title)
                "
                :ref="`menu${i}`"
                v-model="input.menu_data_nasc"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="input.value"
                    :label="
                      input.title
                        .replace(/___/g, '/')
                        .replace(/__/g, '-')
                        .replace(/_/g, ' ')
                    "
                    outlined
                    readonly
                    v-on="on"
                    :background-color="
                      getBackgroundColor(item.desc, input.title)
                        ? color
                        : getBackgroundColorPriority(item.desc, input.title)
                        ? colorPriority
                        : ''
                    "
                    clearable
                    @change="getTheValue(input.title, '', item.desc)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="input.value"
                  @input="
                    input.menu_data_nasc = false;
                    getTheValue(input.title, input.value, item.desc);
                  "
                ></v-date-picker>
              </v-dialog>

              <!-- HOUR PICKER -->

              <v-dialog
                v-else-if="
                  input.type === 'hourPicker' &&
                  displayText(item.desc, input.title)
                "
                v-model="input.menu_hour"
                :close-on-content-click="false"
                persistent
                width="290px"
                :ref="`menu_hour${i}`"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="input.value"
                    :label="
                      input.title
                        .replace(/___/g, '/')
                        .replace(/__/g, '-')
                        .replace(/_/g, ' ')
                    "
                    readonly
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-time-picker format="24hr" v-model="input.value" full-width>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      input.menu_hour = false;
                      getTheValue(input.title, input.value, item.desc);
                    "
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>

              <!-- CHECKBOX -->

              <v-checkbox
                v-else-if="
                  input.type === 'checkbox' &&
                  displayText(item.desc, input.title)
                "
                v-model="input.value"
                :label="
                  input.title
                    .replace(/___/g, '/')
                    .replace(/__/g, '-')
                    .replace(/_/g, ' ')
                "
                @change="getTheValue(input.title, input.value, item.desc)"
              ></v-checkbox>

              <!--BTN-->
              <v-btn
                v-else-if="input.type === 'btn'"
                class="mx-2"
                fab
                :dark="input.dark"
                :color="input.color"
                @click="actionBtn(item.content)"
              >
                <v-icon :dark="input.dark">
                  {{ input.icone }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- BTN PARA DOWNLOAD DE EVENTO: -->

    <v-row v-if="item.desc == 'escritura' && secondRow">
      <v-col cols="12" class="d-flex justify-center">
        <div class="text-center">
          <v-btn
            :dark="!valueICSDisabled"
            color="rgb(196, 194, 44)"
            @click="getValueICS()"
            :disabled="valueICSDisabled"
            class="ma-2"
            v-bind="attrs"
            v-on="on"
          >
            DOWNLOAD EVENTO
            <v-icon right dark class="ml-2"> mdi-arrow-collapse-down </v-icon>
          </v-btn>

          <p v-if="valueICSDisabled" class="caption font-weight-light mt-2">
            Preencha todos os inputs acima para fazer o dowload do evento
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Responsabilities from "@/components/Responsabilities";
import SimpleTab from "@/components/SimpleTab";
import {
  nameOfFamily,
  priorityInput,
} from "@/helpers/LeadInsertData/libraries.js";

export default {
  name: "Inputs",
  props: ["item", "secondRow", "valueICSDisabled", "lead"],
  emits: ["getvalue", "action", "resp", "ics"],

  components: {
    Responsabilities,
    SimpleTab,
  },

  data() {
    return {
      content: "",
      disabledBank: true,
      family: "",

      //Array sobre rendimentos:
      rendimentosArray: [
        "rendimento_liquido_mensal",
        "rendimentos_variaveis",
        "rendimentos_prediais",
        "rendimentos_nao_comprovaveis",
        "outros_rendimentos",
        "rendimento_nao_comprovaveis",
      ],
      despesasArray: [
        "outros_creditos___rendimento",
        "pensao_de_alimentos",
        "despesas_do_agregado",
      ],
      seguroAutomoveis: [
        "matricula_do_carro",
        "marca_do_automovel",
        "modelo_do_automovel",
        "versao",
        "tipo_de_cobertura",
      ],
      seguroSaude: ["n_de_pessoas_asseguradas"],
      seguroVidaCredito: [
        "banco_principal",
        "data_de_duracao_do_emprestimo",
        "cobertura_pretendida",
      ],
      seguroVida: ["data_de_nascimento_seguro", "valor_a_segurar"],
      seguroMultirriscos: [
        "morada_completa_seguros",
        "tipo_de_imovel",
        "ano_de_construcao",
        "area_bruta_privativa",
        "n_de_casas_de_banho",
        "n_de_assoalhos",
      ],
      colorPriority: "#FFEBEE",
    };
  },

  methods: {
    getTheValue(title, value, desc) {
      this.$emit("getvalue", { title, value, desc });
    },
    actionBtn(cond, content) {
      this.$emit("action", { cond, content });
    },
    updateResps: async function (e) {
      this.$emit("resp", e);
      //this.responsabilities[4].value = e;
    },
    getValueICS: async function () {
      this.$emit("ics");
    },
    prepareView: function () {
      if (this.secondRow) {
        this.content = this.item.secondRow;
        this.family = this.item.familySecondRow;
      } else {
        this.content = this.item.content;

        this.family = this.item.family;
      }
    },
    getHintText(title) {
      if (title == "idade") {
        return "O preenchimento será automático após salvar a lead";
      } else return "";
    },

    selectedBank(value) {
      if (value == "Outro") this.disabledBank = false;
    },
    getFamily(index) {
      if (index != 0) {
        return !(this.content[index].family == this.content[index - 1].family);
      } else return true;
    },
    getFamilyName(family) {
      return nameOfFamily[family];
    },
    getBackgroundColor(desc, title) {
      if (desc == "prit_ch" || desc == "sect_ch") {
        if (
          this.rendimentosArray.includes(title) ||
          this.despesasArray.includes(title)
        ) {
          if (Number(this.lead.current_step_id) == 12) return true;
          else return false;
        } else return false;
      } else if (desc == "prit_ss" || desc == "sect_ss" || desc == "seguros") {
        if (
          title == "data_nascimento" ||
          title == "capital_em_divida" ||
          title == "spread_actual_seguradora"
        ) {
          return true;
        } else false;
      } else return false;
    },
    getBackgroundColorPriority(desc, title) {
      if (desc.includes("prit") && priorityInput.includes(title)) {
        return true;
      } else false;
    },

    displayText(desc, title) {
      if (desc == "seguros") {
        if (this.seguroAutomoveis.includes(title)) {
          return this.$store.state.automoveisSeguradora;
        } else if (this.seguroSaude.includes(title))
          return this.$store.state.saudeSeguradora;
        else if (this.seguroVidaCredito.includes(title)) {
          return this.$store.state.vidaCreditoSeguradora;
        } else if (this.seguroVida.includes(title)) {
          return this.$store.state.vidaSeguradora;
        } else if (this.seguroMultirriscos.includes(title)) {
          return this.$store.state.multirriscosSeguradora;
        } else return true;
      } else return true;
    },
    titleFamilyDisplay(number) {
      let title = this.getFamilyName(number - 1);
      if (title == "Automóvel") {
        return this.$store.state.automoveisSeguradora;
      } else if (title == "Vida Crédito") {
        return this.$store.state.vidaCreditoSeguradora;
      } else if (title == "Seguro de Vida") {
        return this.$store.state.vidaSeguradora;
      } else if (title == "Multirriscos") {
        return this.$store.state.multirriscosSeguradora;
      } else if (title == "Seguro de Saúde") {
        return this.$store.state.saudeSeguradora;
      } else return true;
    },
  },
  created() {
    this.prepareView();
  },
  computed: {
    color: function () {
      return this.$store.state.colorBackgroundInput;
    },
    headers() {
      return [
        {
          text: "Banco",
          align: "start",
          sortable: false,
          value: "banco",
        },
        {
          text: "Balcão",
          align: "start",
          sortable: false,
          value: "balcao",
        },
        {
          text: "Observações",
          align: "start",
          sortable: false,
          value: "obs",
        },
      ];
    },
    dataTable() {
      let insertData = this.$store.state.insertData;

      return insertData.concat(this.data);
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-left: 40px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}

h2 {
  margin-left: 40px;
  color: black;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  display: block;
}
.h2-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80%;
  height: 1px;
  background-color: black;
  margin-bottom: -6px;
}

.container {
  margin: 10px 0px;
  max-width: 100%;
}

.colunas {
  padding: 20px 0;
  margin-top: 10px;
}

.colunas-interiores {
  padding: 20px 40px;
}

.v-list-item {
  min-height: 10px;
  padding: 0;
  margin-bottom: 0 !important;
}

.v-list-item__content {
  padding: 8px 0;
}

.v-list-item__title {
  font-size: 14px;
}

h3 {
  font-size: 14px;
  margin-bottom: 10px;
}
.v-item-group {
  padding: 0 30px;
}
.v-list-item__icon {
  margin-right: 10px !important;
  margin-left: 20px;
}

.tabs-container {
  padding: 0px 40px;
  margin-top: 20px;
}

.callbacks-container {
  padding: 0px 40px;
  margin-top: 20px;
  padding-right: 20px;
}

.v-tabs-items {
  padding: 0;
  margin-top: 20px;
}

.inputs-container {
  margin: 0;
  padding: 0;
}

.timeline-date {
  font-size: 14px;
  padding-bottom: 5px !important;
}

.timeline-container {
  margin-top: 20px;
  padding-right: 10px;
}

.col-padding {
  padding: 0px 10px !important;
}

.h3-resumo {
  font-weight: normal;
}

.h2-resumo {
  margin-left: 0;
}

.call-button {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 1;
}
.div-title {
  min-height: 20px;
}

.color-text-field {
  //background: blue !important;
  background-position: 50% 50%;
  border: 2px solid red;
  color: purple;

  fieldset {
    background: green !important;
  }
}
</style>
   