<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn color="#009ddc" small dark fab v-on="on" class="mt-">
          <v-icon small>phone</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Registar interação</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="resultados"
                  item-text="label"
                  item-value="value"
                  label="Resultado"
                  v-model="resultado"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-datetime-picker
                  label="Agendar contacto"
                  clearText="Limpar"
                  okText="Guardar"
                  dateFormat="yyyy-MM-dd"
                  timeFormat="HH:mm"
                  v-model="dataAgendamento"
                  :timePickerProps="{
                    format: '24hr',
                  }"
                  datetime="String"
                  :datePickerProps="{ allowedDates: allowedDates }"
                >
                </v-datetime-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error darken-1"
            text
            @click="
              dialog = false;
              cleanOptions();
            "
            >Anular</v-btn
          >
          <v-btn
            color="success darken-1"
            text
            @click="pushResult"
            :disabled="resultado == ''"
            >Registar</v-btn
          >
        </v-card-actions>
      </v-card>
      <OverlayAlert @confirm="confirmClose()" />
    </v-dialog>
  </v-row>
</template>

<script>
import OverlayAlert from "@/components/OverlayAlert";

export default {
  name: "Calls",
  props: ["resultados", "lead_process_id", "formatDate", "lead", "owner"],
  components: { OverlayAlert },
  data: () => ({
    dialog: false,
    resultado: "",
    menuAgendamento: false,
    dataAgendamento: "",
    dateMin: "",
    moment: require("moment"),
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize: async function () {
      this.dataAgendamento =
        this.lead.callback_date == null
          ? ""
          : new Date(this.lead.callback_date);
    },
    pushResult: async function () {
      let result = this.resultado;

      let date = this.dataAgendamento != null ? new Date(this.dataAgendamento).toISOString().slice(0, -5) : new Date().toISOString().slice(0, -5);

      let owner = "";

      if (this.owner != null || this.owner != "") {
        owner = this.$store.state.consultores.find((e) => e.name == this.owner);

        if (owner != undefined) owner = owner.username;
        else owner = "";
      }

      let username =
        this.lead.username != null && this.lead.username != ""
          ? this.lead.username
          : owner;

      let body = {
        lead_process_id: this.lead_process_id,
        process_step_result: result,
        contact_id: 0,
        username,
        date,
      };

      await this.$store.dispatch("set_new_lead", body);
      const res = this.$store.state.resultApi;

      if (res["resultado"] == "OK") {
        this.$emit("update-history", true);
        this.dialog = false;
      } else if (res["resultado"] == "KO_FINAL") {
        this.$store.commit("SET_OVERLAY_DATA", {
          overlay: true,
          message: res["details"],
          cancel: false
        });

        this.dialog = false;
      } else {
        this.$store.commit("SET_OVERLAY_DATA", {
          overlay: true,
          message: res["details"],
          cancel: false
        });
      }
    },
    confirmClose: async function () {
      this.$emit("update-history-final", true);
      this.$store.commit("SET_OVERLAY_DATA", {
        overlay: false, 
        message: "", 
        cancel: false 
      });
    },
    allowedDates: function (val) {
      let today = new Date(this.moment().subtract(1, "days")).getTime();

      return new Date(val).getTime() >= today;
    },
    cleanOptions: function () {
      this.resultado = "";
      this.dataAgendamento = "";
    },
  },
};
</script>

<style>
</style>