<template>
  <section>
    <h1>
      Taxa de Esforço
      <span class="h1-underline"></span>
    </h1>

    <v-row class="mt-8 mr-4">
      <!-- CARD COM A TAXA DE ESFORÇO -->
      <v-col class="ma-0" :cols="8">
        <v-card elevation="2" class="pt-10 ma-0" min-height="400">
          <h3 class="mb-10">Dados para a simulação da taxa de esforço</h3>

          <v-row>
            <v-col cols="2" class="mx-auto my-auto">
              <v-progress-circular
                :rotate="360"
                :size="150"
                :width="15"
                :value="totalValue"
                :color="titleEsforco.color"
              >
                <p class="progress-text ma-auto">{{ totalValue }}%</p>
              </v-progress-circular>
            </v-col>

            <v-col cols="8" class="ml-16 mr-0 my-auto">
              <v-card
                elevation="2"
                class="ma-0 py-10"
                :color="titleEsforco.color + '  lighten-3'"
                dark
              >
                <v-alert
                  border="top"
                  :color="titleEsforco.color"
                  class="mb-5"
                  dark
                >
                  <h3>{{ titleEsforco.title }}</h3>
                </v-alert>
                <p class="font-weight-light">
                  {{ titleEsforco.text }}
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>


<script>
export default {
  name: "TaxaDeEsforco",
  data() {
    return {
      interval: {},
      rules: [
        (v) => !!v || "Coloque uma quantidade válida!",
        (v) => !/[^\d]+/g.test(v) || "Coloque uma quantidade válida!",
      ],
    };
  },
  computed: {
    totalValue() {
      let totalDespesas = this.$store.state.despesas;
      let totalRendimento = this.$store.state.rendimentos;

      //Proteger o valor para não ser NaN:
      if (totalRendimento == 0) totalRendimento = 1;

      let rest = Math.ceil((totalDespesas * 100) / totalRendimento);

      if (rest > 100) rest = ">" + 100;
      //Isnt number:
      if (isNaN(rest)) rest = 0;

      return rest;
    },

    titleEsforco() {
      if (this.totalValue <= 30) {
        //Alterar as cores do input:
        this.$store.commit("SET_GLOBAL_VARIABLE", {
          var: "colorBackgroundInput",
          value: "teal lighten-4",
        });

        return {
          title: "Taxa de esforço Saudável",
          text:
            " A relação entre os seus rendimentos e os seus encargos mensais está equilibrada e permite-lhe novos investimentos. Contudo, pondere sempre novos gastos com cuidado, resguarde a sua poupança e mantenha o seu orçamento sustentável.",
          color: "teal",
        };
      } else if (this.totalValue > 30 && this.totalValue < 60) {
        //Alterar as cores do input:
        this.$store.commit("SET_GLOBAL_VARIABLE", {
          var: "colorBackgroundInput",
          value: "amber lighten-4",
        });

        return {
          title: "Taxa de esforço Preocupante",
          text:
            " A relação entre os seus rendimentos e os seus encargos mensais está equilibrada e permite-lhe novos investimentos. Contudo, pondere sempre novos gastos com cuidado, resguarde a sua poupança e mantenha o seu orçamento sustentável.",
          color: "amber",
        };
      } else if (this.totalValue >= 60 && this.totalValue <= 100) {
        //Alterar as cores do input:
        this.$store.commit("SET_GLOBAL_VARIABLE", {
          var: "colorBackgroundInput",
          value: "red lighten-5",
        });

        return {
          title: "Taxa de esforço Crítica",
          text:
            "É urgente que reveja o seu orçamento em detalhe e reduza os seus encargos financeiros. Considere renegociar o seu crédito habitação, rever as coberturas dos seguros, consolidar créditos ao consumo, bem como despesas com telecomunicações, ginásios e outras mensalidades. Desta forma conseguirá um maior equilíbrio entre o que recebe e as suas despesas, ganhando um orçamento mais saudável e uma vida também mais descansada.",
          color: "red ",
        };
      } else {
        //Alterar as cores do input:
        this.$store.commit("SET_GLOBAL_VARIABLE", {
          var: "colorBackgroundInput",
          value: "red",
        });

        return {
          title: "Taxa de esforço Incomportável",
          text:
            "A sua Taxa de Esforço está em estado crítico. É urgente que reveja o seu orçamento em detalhe e reduza os seus encargos financeiros. Considere renegociar o seu crédito habitação, rever as coberturas dos seguros, consolidar créditos ao consumo, bem como despesas com telecomunicações, ginásios e outras mensalidades. Desta forma conseguirá um maior equilíbrio entre o que recebe e as suas despesas, ganhando um orçamento mais saudável e uma vida também mais descansada.",
          color: "red lighten-4",
        };
      }
    },
  },
};
</script>

<style scoped>
h1 {
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}
.v-card {
  margin: 10px 40px 50px 296px;
  padding: 40px;
  padding-top: 10px;
}
.v-card__title {
  margin-bottom: 50px;
}

.v-progress-circular {
  margin: 1rem;
}

.progress-text {
  font-size: 40px;
  font-weight: 800;
}

.div__row {
  margin-left: 296px;
}
</style>