<template>
  <v-data-table :headers="headers" :items="items" sort-by="calories">
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Responsabilidades</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="#c4c22c" small dark fab v-on="on">
              <v-icon small>add</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="['Primeiro', 'Segundo', 'Ambos']"
                      label="Titular"
                      v-model="editedItem.titular"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="[
                        'Cartão de Crédito',
                        'Crédito Pessoal',
                        'Crédito Automóvel',
                        'Crédito Habitação',
                        'Mútuo com Hipoteca',
                        'Descoberto Automático',
                        'C. Conta Corrente',
                      ]"
                      label="Tipo de Crédito"
                      v-model="editedItem.tipo_de_credito"
                    ></v-select>
                  </v-col>

                  <v-col cols="8" sm="8" md="8">
                    <v-text-field
                      v-model="editedItem.instituicao"
                      label="Instituição"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-select
                      :items="['Sim', 'Não']"
                      label="Consolidar?"
                      v-model="editedItem.consolidar"
                    ></v-select>
                  </v-col>

                  <v-col cols="4" sm="4" md="4">
                    <v-text-field
                      v-model="editedItem.montante_em_divida"
                      label="Montante"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field
                      v-model="editedItem.prestacao"
                      label="Prestação"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="4" md="4">
                    <v-text-field
                      v-model="editedItem.prazo"
                      label="Prazo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="6" md="6">
                    <v-menu
                      v-model="editedItem.menu_data_de_inicio"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.data_de_inicio"
                          label="Data de início"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.data_de_inicio"
                        @input="editedItem.menu_data_de_inicio = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.numero_de_dossier_externo"
                      label="Dossier Externo"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="save">Inserir</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> edit </v-icon>
      <v-icon small @click="deleteItem(item)"> delete </v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "Responsabilities",
  props: ["resps"],

  data: () => ({
    dialog: false,
    headers: [
      { text: "Titular", value: "titular" },
      { text: "Instituição", value: "instituicao" },
      { text: "Tipo de Crédito", value: "tipo_de_credito" },
      { text: "Montante em Dívida", value: "montante_em_divida" },
      { text: "Prestação", value: "prestacao" },
      { text: "Prazo", value: "prazo" },
      { text: "Data de início", value: "data_de_inicio" },
      { text: "Dossier Externo", value: "numero_de_dossier_externo" },
      { text: "Consolidar", value: "consolidar" },
      { text: "Actions", value: "action", sortable: false },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      consolidar: "",
      data_de_inicio: "",
      instituicao: "",
      montante_em_divida: "",
      numero_de_dossier_externo: "",
      prazo: "",
      prestacao: "",
      tipo_de_credito: "",
      titular: "",
      menu_data_de_inicio: false,
    },
    defaultItem: {
      consolidar: "",
      data_de_inicio: "",
      instituicao: "",
      montante_em_divida: "",
      numero_de_dossier_externo: "",
      prazo: "",
      prestacao: "",
      tipo_de_credito: "",
      titular: "",
      menu_data_de_inicio: false,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Inserir" : "Editar";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      for (let i in this.resps) {
        this.items.push(this.resps[i]);
      }
      // this.items = this.resps
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Tens a certeza que queres eliminar esta linha?") &&
        this.items.splice(index, 1);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }

      this.$emit("update-resps", this.items);

      this.close();
    },
  },
};
</script>

<style>
</style>