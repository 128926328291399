<template>
  <section>
    <v-row>
      <v-col v-for="(variable, i) in variablesSimulador" :key="i" cols="3">
        <v-card class="py-10 px-8" min-height="280">
          <h3 class="mb-5 text-center">{{ variable.title }}:</h3>
          <v-divider></v-divider>
          <table class="mt-2">
            <tr>
              <th>Seguradora:</th>
              <th>Prémio:</th>
              <th>Poupança:</th>
            </tr>

            <tr
              v-for="(item, j) in variable.items"
              :key="`${item}-${j}`"
              class="text-center"
            >
              <v-tooltip right v-if="item.principal">
                <template v-slot:activator="{ on, attrs }">
                  <v-badge color="amber darken-2" icon="mdi-star" overlap>
                    <td
                      v-bind="attrs"
                      v-on="on"
                      class="text-capitalize font-weight-bold"
                    >
                      {{ item.name }}
                    </td>
                  </v-badge>
                </template>
                <span>Melhor Opção</span>
              </v-tooltip>

              <td v-else class="text-capitalize">{{ item.name }}</td>

              <td :class="item.principal ? 'font-weight-bold' : ''">
                {{ item.premio }}€
              </td>
              <td :class="item.principal ? 'font-weight-bold' : ''">
                {{ item.poupanca }}€
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-center">
        <div class="legenda-input"></div>
        <p class="font-weight-light mb-0 ml-3">
          Inputs que influenciam a simulação de Seguros
        </p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "simulacoes",
  props: [
    "dataNascimento",
    "dataNascimentoSegundo",
    "capitalDivida",
    "variablesSimulador",
  ],
};
</script>

<style scoped>
h1 {
  margin-left: 296px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}

.v-card__title {
  margin-bottom: 30px;
}

.btn-download {
  margin-left: 330px !important;
  margin-top: -120px !important;
  width: 70px;
  height: 70px;
  background-color: rgb(246, 147, 30) !important;
}
.btn-download i {
  font-size: 30px !important;
}

.div__row {
  margin-left: 296px;
}

table {
  border-collapse: collapse;
  text-align: center;
}

td,
th {
  /* // border-bottom: 1px solid #dddddd; */
  text-align: center;
  padding: 8px;
  font-size: 12px;
}

.legenda-input {
  width: 30px;
  height: 30px;
  border-radius: 16px;
  background-color: #fff8e1;
}
</style>