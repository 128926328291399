<template>
  <v-overlay z-index="99" :value="overlay" :dark="false">
    <v-card class="mx-auto my-12 py-5 px-8" max-width="450">
      <v-card-text>
        <div class="my-4 font-weight-medium">
          {{ message }}
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          depressed
          outlined
          color="rgb(196, 194, 44)"
          @click="actionCancel"
          v-if="cancel"
        >
          Cancelar
        </v-btn>
        <v-btn
          dark
          color="rgb(196, 194, 44)"
          class="ma-2 white--text"
          @click="actionOk"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: "OverlayAlert",
  emits: ["confirm"],
  methods: {
    actionOk() {
      this.$emit("confirm");
    },
    actionCancel() {
      this.$store.commit("SET_OVERLAY_DATA", {
        overlay: false,
        message: "",
        cancel: false
      });
    },
  },

  computed: {
    message: function () {
      return this.$store.state.messageOverlay;
    },
    overlay: function () {
      return this.$store.state.overlayStatus;
    },
    cancel() {
      return this.$store.state.overlayCancel;
    }
  },
};
</script>

<style>
</style>