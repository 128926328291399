var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Responsabilidades")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#c4c22c","small":"","dark":"","fab":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":['Primeiro', 'Segundo', 'Ambos'],"label":"Titular"},model:{value:(_vm.editedItem.titular),callback:function ($$v) {_vm.$set(_vm.editedItem, "titular", $$v)},expression:"editedItem.titular"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-select',{attrs:{"items":[
                      'Cartão de Crédito',
                      'Crédito Pessoal',
                      'Crédito Automóvel',
                      'Crédito Habitação',
                      'Mútuo com Hipoteca',
                      'Descoberto Automático',
                      'C. Conta Corrente' ],"label":"Tipo de Crédito"},model:{value:(_vm.editedItem.tipo_de_credito),callback:function ($$v) {_vm.$set(_vm.editedItem, "tipo_de_credito", $$v)},expression:"editedItem.tipo_de_credito"}})],1),_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('v-text-field',{attrs:{"label":"Instituição"},model:{value:(_vm.editedItem.instituicao),callback:function ($$v) {_vm.$set(_vm.editedItem, "instituicao", $$v)},expression:"editedItem.instituicao"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-select',{attrs:{"items":['Sim', 'Não'],"label":"Consolidar?"},model:{value:(_vm.editedItem.consolidar),callback:function ($$v) {_vm.$set(_vm.editedItem, "consolidar", $$v)},expression:"editedItem.consolidar"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Montante"},model:{value:(_vm.editedItem.montante_em_divida),callback:function ($$v) {_vm.$set(_vm.editedItem, "montante_em_divida", $$v)},expression:"editedItem.montante_em_divida"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Prestação"},model:{value:(_vm.editedItem.prestacao),callback:function ($$v) {_vm.$set(_vm.editedItem, "prestacao", $$v)},expression:"editedItem.prestacao"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Prazo"},model:{value:(_vm.editedItem.prazo),callback:function ($$v) {_vm.$set(_vm.editedItem, "prazo", $$v)},expression:"editedItem.prazo"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data de início","readonly":""},model:{value:(_vm.editedItem.data_de_inicio),callback:function ($$v) {_vm.$set(_vm.editedItem, "data_de_inicio", $$v)},expression:"editedItem.data_de_inicio"}},on))]}}]),model:{value:(_vm.editedItem.menu_data_de_inicio),callback:function ($$v) {_vm.$set(_vm.editedItem, "menu_data_de_inicio", $$v)},expression:"editedItem.menu_data_de_inicio"}},[_c('v-date-picker',{on:{"input":function($event){_vm.editedItem.menu_data_de_inicio = false}},model:{value:(_vm.editedItem.data_de_inicio),callback:function ($$v) {_vm.$set(_vm.editedItem, "data_de_inicio", $$v)},expression:"editedItem.data_de_inicio"}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Dossier Externo"},model:{value:(_vm.editedItem.numero_de_dossier_externo),callback:function ($$v) {_vm.$set(_vm.editedItem, "numero_de_dossier_externo", $$v)},expression:"editedItem.numero_de_dossier_externo"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Inserir")])],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" edit ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" delete ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }