<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="#c4c22c"
          fab
          x-small
          dark
          v-on="on"
          @click="
            changeVisualBtn(false);
            formatContent();
          "
          ><v-icon>create</v-icon></v-btn
        >
      </template>
      <v-card>
        <v-toolbar dark color="#c4c22c">
          <v-btn icon dark @click="confirmClose(true)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <div class="reports">
          <h1>
            {{ lead.current_step }} - processo {{ lead.lead_process_id
            }}<span class="h1-underline"></span>
          </h1>

          <div class="container">
            <v-container>
              <v-row>
                <v-col :cols="9">
                  <v-card class="colunas">
                    <v-row>
                      <v-col cols="10">
                        <h2>
                          Cliente - {{ lead.nome
                          }}<span class="h2-underline"></span>
                        </h2>
                      </v-col>
                      <v-col cols="1" class="text-right">
                        <!-- BTN Email -->
                        <v-btn color="#009ddc" small dark fab @click="emailTo">
                          <v-icon small>mdi-email</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1">
                        <!-- BTN CALL -->
                        <Calls
                          :resultados="radioElem"
                          :lead="lead"
                          :owner="owner.owner"
                          :lead_process_id="lead.lead_process_id"
                          :formatDate="formatDate"
                          @update-history="reloadData"
                          @update-history-final="reloadDataFinal"
                        />
                      </v-col>
                    </v-row>

                    <!-- TABS -->

                    <template>
                      <v-card flat class="tabs-container">
                        <!-- HEADER TAB -->
                        <v-tabs v-model="tab" color="#c4c22c">
                          <v-tab
                            v-for="item in items"
                            :key="item.tab"
                            v-show="
                              item.tab == 'Owner' ? Number(userlevel) > 1 : true
                            "
                            @click="setAttributesSeguros()"
                          >
                            {{ item.tab }}
                          </v-tab>
                        </v-tabs>
                        <!-- BODY TAB -->

                        <v-tabs-items v-model="tab">
                          <v-tab-item v-for="item in items" :key="item.tab">
                            <!-- RESUMO - TEXTO -->
                            <v-row
                              v-if="
                                item.desc === 'obs' ||
                                item.desc === 'obs_ch' ||
                                item.desc === 'obs_ss'
                              "
                            >
                              <v-col :cols="12" class="col-padding">
                                <v-card flat>
                                  <!-- <v-card-title class="inputs-container mb-5">
                                    DADOS:
                                  </v-card-title> -->
                                  <v-card-text class="inputs-container">
                                    <!-- RESUMO EM TEXTO -->
                                    
                                    <div v-for="(text, i) in item.content" :key="i">
                                      <v-row class="px-5">
                                        <v-col :cols="text.cols">
                                          <h2 class="ml-0">
                                            {{ text.title }}<span class="h2-underline"></span>
                                          </h2>
                                        </v-col>
                                      </v-row>

                                      <v-row class="px-5">
                                        <v-col
                                          v-for="(el, j) in text.content"
                                          :key="j"
                                          :cols="el.cols"
                                          class="colunas-interiores py-1"
                                          v-show="el.value != ''"
                                        >
                                          <div class="my-0 py-0 resumo">
                                            <p class="my-0 font-weight-bold text-capitalize subtitle-2">
                                              {{
                                                el.title
                                                  .replace(/___/g, "/")
                                                  .replace(/__/g, "-")
                                                  .replace(/_/g, " ")
                                              }}
                                            </p>
                                            <template v-if="el.title != 'dependentes__seguros'">
                                              <p class="font-weight-light text-capitalize my-0 caption">
                                                {{ el.value }}
                                              </p>
                                            </template>
                                            <template v-else>
                                              <p 
                                              v-for="(val,k) in el.value"
                                              :key="k"
                                              class="font-weight-light my-0 text-capitalize caption dependentes">
                                                Nome: {{ val.name }} <br>
                                                Data nascimento: {{ val.dataAniversario }} <br>
                                                NIF: {{ val.contribuinte }}
                                              </p>
                                            </template>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </div>

                                    <!-- OBSERVAÇOES: -->

                                    <v-row>
                                      <v-col
                                        v-for="(text, i) in item.secondRow"
                                        :key="i"
                                        :cols="text.cols"
                                      >
                                        <v-textarea
                                          v-if="text.type === 'textarea'"
                                          outlined
                                          :label="text.title"
                                          :value="text.value"
                                          v-model="text.value"
                                          @change="
                                            getTheValue({
                                              title: text.title,
                                              value: text.value,
                                              desc: 'obs',
                                            })
                                          "
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>

                                    <v-spacer></v-spacer>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>

                            <!-- DOCUMENTS LIST -->
                            <v-row v-if="item.desc === 'docs'">
                              <v-col cols="12">
                                <v-card flat>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="12">
                                        <!-- DATA TABLE -->
                                        <v-data-table
                                          :headers="docsHeaders"
                                          :items="documents"
                                          loading-text="A carregar..."
                                        >
                                          <template v-slot:[`item.view`]="{ item }">
                                            <v-btn
                                              color="#c4c22c"
                                              small
                                              dark
                                              fab
                                              :href="item.url"
                                              target="_blank"
                                            >
                                              <v-icon small>search</v-icon>
                                            </v-btn>
                                          </template>

                                          <!-- <template v-slot:item.download="{ item }">
                                                <v-btn
                                                  color="#009ddc"
                                                  small
                                                  dark
                                                  fab
                                                  :href="item.url"
                                                  download
                                                >
                                                  <v-icon small>mdi-cloud-download</v-icon>
                                                </v-btn>
                                              </template> -->
                                        </v-data-table>
                                      </v-col>
                                    </v-row>
                                    <v-row class="mt-5">
                                      <v-col
                                        cols="12"
                                        class="d-flex justify-center"
                                      >
                                        <!-- HEADER -->
                                        <v-btn
                                          color="#c4c22c"
                                          dark
                                          :href="`https://app.reorganiza.pt/uploads/14e4e6afd220e4b720e5382c937170b04d3adb31442a56601486950374956ac3/${lead.lead_process_id}`"
                                          target="_blank"
                                        >
                                          <v-icon left dark class="mx-3">
                                            mdi-file-upload-outline
                                          </v-icon>
                                          Upload de Documentos
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>

                            <!-- TEXT FIELD  -->
                            <div
                              v-else-if="
                                item.desc != 'obs' &&
                                item.desc != 'obs_ch' &&
                                item.desc != 'obs_ss'
                              "
                            >
                              <!------ PRIMEIRA LINHA -------->

                              <Inputs
                                :item="item"
                                :secondRow="false"
                                @getvalue="getTheValue"
                                @action="actionBtn"
                                @resp="updateResps"
                                @ics="getValueICS"
                                :valueICSDisabled="valueICSDisabled"
                                :lead="lead"
                              >
                              </Inputs>

                              <!-- NUMERO DE DEPENDENTES: -->

                              <div
                                v-for="i in numberChild"
                                :key="i"
                                v-show="saudeSeguradora"
                              >
                                <numberOfChild
                                  v-if="item.desc == 'seguros'"
                                  :order="i"
                                  @getvalue="getChildValues"
                                  :values="
                                    i - 1 < seguros.dependentes__seguros.length
                                      ? seguros.dependentes__seguros[i - 1]
                                      : {
                                          name: '',
                                          dataAniversario: '',
                                          contribuinte: '',
                                        }
                                  "
                                />
                              </div>

                              <!------ SEGUNDA LINHA -------->

                              <Inputs
                                :item="item"
                                :secondRow="true"
                                @getvalue="getTheValue"
                                @action="actionBtn"
                                @resp="updateResps"
                                @ics="getValueICS"
                                :valueICSDisabled="valueICSDisabled"
                                :lead="lead"
                              >
                              </Inputs>
                            </div>

                            <!-- LEGENDAS -->
                            <v-row>
                              <v-col>
                                <div class="d-flex">
                                  <div class="legenda-input"></div>
                                  <p class="ml-3 pb-0 my-auto">
                                    Campos com preenchimento prioritário
                                  </p>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row>
                              <!-- TAXA DE ESFORÇO -->
                              <v-col
                                cols="10"
                                v-if="
                                  item.desc == 'prit_ch' ||
                                  item.desc == 'sect_ch'
                                "
                              >
                                <!-- VISUALIZAR APENAS PARA OS TIPOS INICIAIS-->

                                <TaxadeEsforco
                                  v-if="lead.current_step_id == 12"
                                />
                              </v-col>

                              <!-- SIMULADOR SEGUROS -->
                              <v-col
                                cols="10"
                                v-if="
                                  (item.desc == 'seguros' ||
                                    item.desc == 'prit_ss' ||
                                    item.desc == 'sect_ss') &&
                                  lead.current_step_id == '35'
                                "
                                class="ma-0"
                              >
                                <Simulacoes
                                  :dataNascimento="prit.data_nascimento"
                                  :dataNascimentoSegundo="sect.data_nascimento"
                                  :capitalDivida="seguros.capital_em_divida"
                                  :variablesSimulador="variablesSimulador"
                                />
                              </v-col>

                              <!-- BTN PARA SALVAR -->
                              <v-col
                                :cols="
                                  item.desc == 'prit_ch' ||
                                  item.desc == 'sect_ch'
                                    ? 2
                                    : 12
                                "
                                class="d-flex justify-end"
                              >
                                <div class="text-right">
                                  <v-btn
                                    x-large
                                    color="rgb(196, 194, 44)"
                                    class="ma-2 white--text"
                                    @click="updateLeadData"
                                  >
                                    SALVAR
                                    <v-icon right dark class="ml-2">
                                      mdi-content-save
                                    </v-icon>
                                  </v-btn>

                                </div>
                              </v-col>
                            </v-row>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-card>
                    </template>
                  </v-card>
                </v-col>

                <!-- COLUNA DE CONTATO -->
                <v-col :cols="3">
                  <v-card class="colunas">
                    <h2>Próximo Contacto<span class="h2-underline"></span></h2>
                    <v-card
                      flat
                      class="callbacks-container"
                      v-if="this.lead.callback_date == null"
                    >
                      Nenhum contacto agendado
                    </v-card>
                    <v-card flat class="callbacks-container" v-else>
                      {{ this.lead.callback_date }}
                    </v-card>
                  </v-card>

                  <v-card class="colunas">
                    <h2>Histórico<span class="h2-underline"></span></h2>
                    <v-timeline align-top dense class="timeline-container">
                      <v-timeline-item
                        v-for="(hist, i) in historico"
                        :key="i"
                        fill-dot
                        right
                        small
                        color="#999"
                      >
                        <v-card class="elevation-2">
                          <v-card-title class="timeline-date">{{
                            hist.data
                          }}</v-card-title>
                          <v-card-text>
                            <b>Resultado:</b> {{ hist.resultado }}
                            <br />
                            <b>Estado:</b> {{ hist.estado }}
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card>
                  
                  <v-card class="colunas">
                    <h2>Outros dados<span class="h2-underline"></span></h2>
                    <p class="ml-10 mt-5">
                      Origem: {{ extraInfo.origem_lp }}
                      <br />
                      Consultor: {{ extraInfo.consultor_lp }}
                      <br />
                      URL: {{ extraInfo.url }}
                    </p>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-card>

      <OverlayAlert @confirm="confirmClose(false)" />
    </v-dialog>
  </v-row>
</template>

<script>
import Calls from "@/components/Calls";
import OverlayAlert from "@/components/OverlayAlert";
import Inputs from "@/components/Inputs";
import TaxadeEsforco from "@/components/TaxadeEsforco";
import numberOfChild from "@/components/numberOfChild";
import Simulacoes from "@/components/Simulacoes";

//Helpers
import {
  populateItemstHelper,
  populateContentHelper,
  groupLeadDetailsHelper,
} from "@/helpers/LeadInsertData/formContent.js";

import { productInputs } from "@/helpers/LeadInsertData/libraries.js"

import { leadComplete } from '@/helpers/LeadInsertData/modelCompleteLead.js';

const initialState = () => {
  return {
    snackbar: false,

    type: "",
    snackbarMessage: "",
    snackbarColor: "",
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    tab: null,
    resume: [],
    items: [
      { tab: "Resumo", desc: "obs", content: [] },
      { tab: "1º Titular", desc: "prit", content: [] },
      { tab: "2º Titular", desc: "sect", content: [] },
      { tab: "Operação", desc: "operation_data", content: [] },
      { tab: "Responsabilidades", desc: "responsabilities", content: [] },
      { tab: "Parecer", desc: "comercial_assessment", content: [] },
      { tab: "Documentos", desc: "docs", content: [] },
    ],

    radioElem: [],

    historico: [],
    extraInfo: {},
    date: "",

    //Variáveis:
    prit: {
      titulo: "",
      nome: "",
      estado_civil: "",
      data_nascimento: "",
      telefone: "",
      email: "",
      nacionalidade: "",
      naturalidade: "",
      tipo_de_documento: "",
      numero_do_documento: "",
      validade_do_documento: "",
      nif: "",
      dependentes: "",
      entidade_patronal: "",
      empregado_desde: "",
      vinculo: "",
      profissao: "",
      sector: "",
      rendimento_liquido_mensal: "",
      rendimentos_variaveis: "",
      rendimentos_prediais: "",
      pensao_de_alimentos: "",
      rendimentos_nao_comprovaveis: "",
      outros_rendimentos: "",
      proveniencia_de_outros_rendimentos: "",
      despesas_do_agregado: "",
      rendimento_nao_comprovaveis: "",
      outros_creditos___rendimento: "",
      iban: "",
      ano_de_abertura_da_conta: "",
      tipo_de_habitacao: "",
      morada: "",
      codigo__postal: "",
      localidade: "",
      vive_nesta_casa_desde: "",
      residencia: "",
      incumprimento: '',
      titulares_total: '',
      comment: ''
    },
    sect: {
      titulo: "",
      nome: "",
      estado_civil: "",
      data_nascimento: "",
      telefone: "",
      email: "",
      nacionalidade: "",
      naturalidade: "",
      tipo_de_documento: "",
      numero_do_documento: "",
      validade_do_documento: "",
      nif: "",
      dependentes: "",
      entidade_patronal: "",
      empregado_desde: "",
      vinculo: "",
      profissao: "",
      sector: "",

      rendimento_liquido_mensal: "",
      rendimentos_variaveis: "",
      rendimentos_prediais: "",
      pensao_de_alimentos: "",
      rendimentos_nao_comprovaveis: "",
      outros_rendimentos: "",
      proveniencia_de_outros_rendimentos: "",

      iban: "",
      ano_de_abertura_da_conta: "",
      tipo_de_habitacao: "",
      morada: "",
      codigo__postal: "",
      localidade: "",
      vive_nesta_casa_desde: "",
      residencia: "",
    },
    operation_data: {
      operacao: "",
      prazo_pretendido: "",
      valor_do_financiamento___em_divida: "",
      adesao_ao_seguro: "",
      liquidez_adicional: "",
      data_da_contratacao: "",
      spread_actual: "",
      valor_do_multiopcoes: "",
      tipo_de_operacao: "",
      fiador: "",
    },
    responsabilities: {
      renda___prestacao_ch_1t: "",
      pensao_de_alimentos_1t: "",
      renda___prestacao_ch_2t: "",
      pensao_de_alimentos_2t: "",
      resp: "",
    },
    comercial_assessment: {
      comercial_assessment: "",
    },
    owner: {
      owner: "",
    },
    seguros: {
      seguradora_atual: "",
      premio_atual: "",
      capital_em_divida: "",
      spread_actual_seguradora: "",
      produto_seguradora: "",
      seguradora_futura: "",
      premio_proposto: "",
      banco_principal: "",
      data_de_duracao_do_emprestimo: "",
      n_de_pessoas_asseguradas: "",
      data_da_carta_de_conducao: "",
      matricula_do_carro: "",
      dependentes__seguros: "",
      marca_do_automovel: "",
      modelo_do_automovel: "",
      versao: "",
      tipo_de_cobertura: "",
      cobertura_pretendida: "",
      morada_completa_seguros: "",
      tipo_de_imovel: "",
      ano_de_construcao: "",
      area_bruta_privativa: "",
      n_de_casas_de_banho: "",
      n_de_assoalhos: "",
      data_de_nascimento_seguro: "",
      valor_a_segurar: "",
    },
    avaliacao: {
      resultado_da_avaliacao: "",
      suficiente: "",
    },
    escritura: {
      data_da_escritura: "",
      hora_da_escritura: "",
      local_da_escritura: "",
      banco_principal_escritura: "",
      agencia: "",
      valor_escritura: "",
      avaliacao_do_imovel: "",
      valor_do_orcamento: "",
      outros_creditos: "",
      localizacao_imovel: "",
      operacao: "",
      tipo_de_operacao_escritura: "",
      montante_a_consolidar___financiar: "",
      prazo_pretendido_escritura: "",
    },
    obs: {
      observations: "",
    },
    banco: {},

    dateFormatted: "",

    childValues: [],

    //FIM Variáveis

    listOfDependentes: [],
    moment: require("moment"),

    pri_data: [],
    sec_data: [],
    operation: [],
    assessement: [],
    documents: [],

    dados: [],
    docsHeaders: [
      { text: "Documento", align: "center", value: "doc", sortable: false },
      {
        text: "Data Upload",
        align: "center",
        value: "upload_date",
        sortable: false,
      },
      {
        text: "Ver Documento",
        align: "right",
        value: "view",
        sortable: false,
      },
      // { text: "Download", align: "center", value: "download", sortable: false }
    ],

    resume_ch_credit: [], //Lista de inputs em resume Segunda Row
    titular_ch_credit: [],
    disabledBank: true,
    age: "",

    userlevel: window.sessionStorage.getItem("user_level"),
    username: window.sessionStorage.getItem("username"),

    //Array sobre rendimentos:
    rendimentosArray: [
      "rendimento_liquido_mensal",
      "rendimentos_variaveis",
      "rendimentos_prediais",

      "rendimentos_nao_comprovaveis",
      "outros_rendimentos",

      "rendimento_nao_comprovaveis",
    ],
    despesasArray: [
      "outros_creditos___rendimento",
      "pensao_de_alimentos",
      "despesas_do_agregado",
    ],
  };
}

export default {
  components: {
    Calls,
    TaxadeEsforco,
    Inputs,
    OverlayAlert,
    numberOfChild,
    Simulacoes,
  },
  name: "LeadInsertData",
  props: ["lead"],
  emits: ["snackbar"],
  data() {
    return initialState();
  },
  methods: {
    reloadData: async function () {
      this.$emit("update-made", true);
    },
    changeVisualBtn: function (cond) {
      this.$store.commit("SET_BTN_EXPORT_EXCEL", cond);
    },
    reloadDataFinal: async function () {
      this.$emit("update-made", true);
      this.dialog = false;
    },
    setAttributesSeguros() {
      // value altera para true de acordo com o produto selecionado
      let attributesSegurosValue = [
        {
          title: "Vida",
          variable: "vidaSeguradora",
          value: false,
        },
        {
          title: "Automóvel",
          variable: "automoveisSeguradora",
          value: false,
        },
        {
          title: "Vida Crédito",
          variable: "vidaCreditoSeguradora",
          value: false,
        },
        {
          title: "Multirriscos",
          variable: "multirriscosSeguradora",
          value: false,
        },
        {
          title: "Saúde",
          variable: "saudeSeguradora",
          value: false,
        },
        {
          title: "Outro",
          variable: "outroSeguradora",
          value: false,
        },
      ];

      let title = this.seguros.produto_seguradora;

      // Preencher info dos dependentes no caso de seguro de saúde
      if (
        title == "Saúde" &&
        this.seguros.n_de_pessoas_asseguradas != null &&
        this.seguros.n_de_pessoas_asseguradas != "" &&
        this.seguros.n_de_pessoas_asseguradas != "0"
      ) {
        this.$store.commit("SET_GLOBAL_VARIABLE", {
          var: "numberOfChild",
          value: Number(this.seguros.n_de_pessoas_asseguradas),
        });

        this.childValues = this.seguros.dependentes__seguros.map((e, i) => ({
          id: i + 1,
          name: e.name,
          dataAniversario: e.dataAniversario,
          contribuinte: e.contribuinte,
        }));
      }
      
      // Habilitar novos inputs de acordo com o produto selecionado
      let variable;
      if(title != undefined && title != null && title != "") {
        let index = attributesSegurosValue.findIndex((e) => e.title == title);
        variable = attributesSegurosValue[index].variable;

        attributesSegurosValue[index] = {
          title: attributesSegurosValue[index].title,
          variable: attributesSegurosValue[index].variable,
          value: true,
        };

      } else {
        this.seguros.produto_seguradora = "";
        variable = "";
      }

      // limpar inputs dos produtos não selecionados
      for (let i in productInputs) {
        if(i != variable) {
          for (let j of productInputs[i]) {
            this.seguros[j] = j == "dependentes__seguros" ? [] : "";
          }
        }
      }

      for (let i in attributesSegurosValue) {
        this.$store.commit("SET_GLOBAL_VARIABLE", {
          var: attributesSegurosValue[i].variable,
          value: attributesSegurosValue[i].value,
        });
      }

      if (this.seguros.produto_seguradora == "Automóvel") {
        this.childValues = [];
      }
    },
    getTheValue(content) {
      //Função responsável pelo preenchimento do valor alterado no input:
      let { title, value, desc } = content;

      let firstValue = desc.includes("prit")
        ? "prit"
        : desc.includes("sect")
        ? "sect"
        : desc.includes("operation_data")
        ? "operation_data"
        : title == "comercial_assessment"
        ? "comercial_assessment"
        : desc;

      let titleReal =
        title != "Consultores"
          ? title != "n_de_dependentes"
            ? title
            : "dependentes"
          : "owner";

      if (firstValue != "obs_bank") this[firstValue][titleReal] = value;

      // console.log(`this[${firstValue}][${titleReal}] = ${value}`);

      //Mudança da idade para API:
      if (titleReal == "data_nascimento") {
        this[firstValue]["idade"] = this.moment().diff(value, "years");
      }

      if (firstValue == "obs_bank" && titleReal == "banco") {
        this["escritura"]["banco_principal_escritura"] = value;
      }

      //Push para despesas e rendimentos:

      if (
        (desc == "prit_ch" || desc == "sect_ch") &&
        (this.rendimentosArray.includes(title) ||
          this.despesasArray.includes(title))
      ) {
        this.getValuesEsforco();
      }

      if (desc == "prit_ss" || desc == "sect_ss") {
        if (title == "data_nascimento") {
          this.getValuesSimulacoes();
        }
      }

      //Alterar Valor quando é escolhido certo produto da Seguradora
      //Para habilitar mais campos de preenchimento:
      if (desc == "seguros") {
        if (title == "produto_seguradora") {
          this.setAttributesSeguros();
        } else if (title == "n_de_pessoas_asseguradas") {
          if (
            value != "" &&
            value != "0" &&
            value != this.seguros.dependentes__seguros.length &&
            this.seguros.dependentes__seguros.length > 0
          ) {
            this.childValues = this.seguros.dependentes__seguros.map(
              (e, i) => ({
                id: i + 1,
                name: e.name,
                dataAniversario: e.dataAniversario,
                contribuinte: e.contribuinte,
              })
            ).filter((_,i) => i < value);
          } else {
            this.childValues = [];
            this.seguros.dependentes__seguros = [];
          }

          this.$store.commit("SET_GLOBAL_VARIABLE", {
            var: "numberOfChild",
            value: value,
          });
        }

        this.getValuesSimulacoes();
      }
    },
    getValueICS() {
      let nameOrganizer = window.sessionStorage.getItem("name");
      let emailOrganizer = window.sessionStorage.getItem("email");

      let begin = `${this.escritura.data_da_escritura}T${this.escritura.hora_da_escritura}:00`;

      begin = this.moment(begin).format();

      let stop = this.moment(begin).add(1, "hours").format();

      let description = ``;
      //let description = `${this.obs.observations}, Banco: ${this.escritura.banco_principal}, Agencia: ${this.escritura.agencia}`;

      let location = this.escritura.local_da_escritura;

      this.$ics.addEvent(
        "en-us",
        `Convite - ${this.lead.nome}`,
        description,
        location,
        begin,
        stop,
        "",
        { name: nameOrganizer, email: emailOrganizer }
      );

      this.$ics.calendar();
      this.$ics.download("evento");
    },
    actionBtn(value) {
      let { content } = value;
      let arr = [];
      //enviar para a store;

      arr.push({
        banco: content[0].value,
        balcao: content[1].value,
        obs: content[2].value,
      });

      this.$store.commit("SET_INSERT_DATA", arr);
      content[0].value = "";
      content[1].value = "";
      content[2].value = "";
    },
    confirmClose: async function (cond) {
      if (cond) {
        this.$store.commit("SET_OVERLAY_DATA", {
          overlay: true,
          message:
            "Estás prestes a fechar a ficha do processo.\nAs alterações não guardadas poderão ser perdidas.\n\nTens a certeza que pretendes continuar?",
          cancel: true
        });
      } else {
        this.$store.commit("SET_OVERLAY_DATA", {
          overlay: false, 
          message: "",
          cancel: false
        });

        this.changeVisualBtn(true);
        this.dialog = false;
      }
    },
    selectedBank(value) {
      if (value == "Outro") this.disabledBank = false;
    },
    updateLeadData: async function () {
      const res = await this.updateLeadDetails();

      this.$emit("snackbar", res);

      this.snackbar = true;

      this.reloadData();

      this.dialog = false;
    },
    updateLeadDetails: async function () {
      let banco = this.$store.state.insertData.concat(this.banco);

      this.$store.commit("SET_GLOBAL_VARIABLE", {
        var: "insertData",
        value: [],
      });

      // console.log("childValues", this.childValues);

      if (this.childValues.length > 0) {
        this.seguros.dependentes__seguros = this.childValues.map((e) => ({
          name: e.name,
          dataAniversario: e.dataAniversario,
          contribuinte: e.contribuinte,
        }));
      } else this.seguros.dependentes__seguros = [];

      // console.log(
      //   " this.seguros.dependentes__seguros",
      //   this.seguros.dependentes__seguros
      // );

      let body = {
        prit_data: this.prit,
        sect_data: this.sect,
        operation_data: this.operation_data,
        responsabilities: this.responsabilities,
        comercial_assessment: this.comercial_assessment.comercial_assessment,
        observations: this.obs.observations,
        owner: this.owner.owner,
        escritura: this.escritura,
        avaliacao: this.avaliacao,
        seguros: this.seguros,
        banco,
        contact_id: "0",
      };

      // console.log("updateLeadDetails: id",this.lead.lead_process_id,"body",body);

      await this.$store.dispatch("update_lead_details", {
        body,
        process_id: this.lead.lead_process_id,
      });

      const res = this.$store.state.resultApi;
      return res;
    },
    updateResps: async function (e) {
      this.responsabilities[4].value = e;
    },
    groupLeadDetails: function () {
      let dados = groupLeadDetailsHelper(this.completeLead);

      this.dados = dados;
    },
    collectLeadHistory: async function () {
      await this.$store.dispatch(
        "collect_lead_history",
        this.lead.lead_process_id
      );
      this.historico = this.$store.state.leadHistory;

      await this.collectPossibleResults();
    },
    collectPossibleResults: async function () {
      await this.$store.dispatch(
        "collect_possible_results",
        this.lead.current_step_id
      );

      const dados = this.$store.state.possibleResults;

      let temp = [];

      for (let i in dados) {
        temp.push({
          label: dados[i].name,
          value: dados[i].id,
        });
      }

      this.radioElem = temp;
    },
    collectExtraLeadData: async function () {
      await this.$store.dispatch("collect_extra_lead_data",this.lead.lead_id);

      let extraInfo = this.$store.state.single_lead_center_process_id[0];
      if(extraInfo.consultor_lp == null && extraInfo.origem_lp == null) extraInfo.origem_lp = "Lead Center";
      this.extraInfo = extraInfo;
    },
    collectLeadDocuments: async function () {
      await this.$store.dispatch(
        "collect_lead_documents",
        this.lead.lead_process_id
      );

      this.documents = this.$store.state.processFiles;
    },
    populateFormContent: async function (type) {
      let items = await populateItemstHelper(type);
      this.items = await populateContentHelper(items, this.dados);
    },
    formatDate: async function (date) {
      if (date == null || date == "null") {
        return false;
      } else {
        let hours =
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let minutes = date.getMinutes();
        let dia = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let mes =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;

        let strTime = hours + ":" + minutes + ":00";

        return date.getFullYear() + "-" + mes + "-" + dia + " " + strTime;
      }
    },
    typeLead: function () {
      let type = this.lead.produto;

      switch (type) {
        case "credito_consolidado":
          this.type = "CC";
          this.populateFormContent("CC");
          break;
        case "credito_pessoal":
          this.type = "CP";
          this.populateFormContent("CP");
          break;
        case "credito_habitacao":
          this.type = "CH";
          this.populateFormContent("CH");
          break;
        case "seguro_de_vida":
          this.type = "SS";
          this.populateFormContent("SS");
          break;
        default:
          this.type = "CC";
          this.populateFormContent("CC");
      }
    },
    formatContent: function () {
      this.prit = this.lead.prit_data || Object.assign({},leadComplete.prit_data);
      this.sect = this.lead.sect_data || Object.assign({},leadComplete.sect_data);

      let items = ['operation_data','responsabilities','escritura','avaliacao','seguros','banco'];

      for(let i of items) {
        if(this.lead[i] == null || this.lead[i] == undefined) this[i] = Object.assign({},leadComplete[i]);
        else this[i] = this.lead[i];
      }
      
      this.comercial_assessment.comercial_assessment = this.lead.comercial_assessment || '';

      this.owner.owner =
        this.lead.owner != null
          ? this.lead.owner.replace(/"/g, "").replace(/\//g, "")
          : ''

      this.obs.observations =
        this.lead.observations != null && this.lead.observations != undefined
          ? this.lead.observations
          : ''

      this.completeLeadData();

      //Push para despesas e rendimentos:
      this.getValuesEsforco();
      //Enviar dados para simuladores:
      this.getValuesSimulacoes();
    },
    completeLeadData() {
      let fields = ["nome","email","telefone"];
      for(let i of fields) {
        if(this.prit[i] == "") this.prit[i] = this.lead[i];
      }

      let type = this.lead.produto;

      let auxArr = [];

      if(type == 'seguro_de_vida') {
        auxArr = [
          {1: "seguros", 2: "capital_em_divida", 3: "aux_1"},
          {1: "prit", 2: "data_nascimento", 3: "aux_2"},
          {1: "sect", 2: "data_nascimento", 3: "aux_3"},
        ]
      } else if(type == 'credito_pessoal') {
        auxArr = [
          {1: "operation_data", 2: "valor_do_financiamento___em_divida", 3: "aux_1"},
          {1: "operation_data", 2: "prazo_pretendido", 3: "aux_2"},
        ]
      } else if(type == 'credito_consolidado') {
        auxArr = [
          {1: "operation_data", 2: "valor_do_financiamento___em_divida", 3: "aux_1"},
          {1: "operation_data", 2: "prazo_pretendido", 3: "aux_2"},
          {1: "prit", 2: "incumprimento", 3: "aux_3"},
          {1: "prit", 2: "vinculo", 3: "aux_4"},
          {1: "prit", 2: "rendimento_liquido_mensal", 3: "aux_5"},
          {1: "prit", 2: "tipo_de_habitacao", 3: "aux_6"},
          {1: "prit", 2: "titulares_total", 3: "aux_7"},
          {1: "prit", 2: "data_nascimento", 3: "aux_8"},
          {1: "sect", 2: "data_nascimento", 3: "aux_9"},
          {1: "prit", 2: "comment", 3: "aux_10"},
        ]
      }

      for(let i of auxArr) {
        if(this[i[1]][i[2]] == "" || this[i[1]][i[2]] == undefined) this[i[1]][i[2]] = this.lead[i[3]]
      }
    },
    getValuesSimulacoes: async function () {
      if (this.lead.produto == "seguro_de_vida") {
        let dataNascimento = this.prit.data_nascimento;
        let dataNascimentoSegundo =
          this.sect.data_nascimento == "" ? null : this.sect.data_nascimento;
        let capitalDivida = this.seguros.capital_em_divida;

        this.$store.commit("SET_GLOBAL_VARIABLE", {
          var: "colorBackgroundInput",
          value: "amber lighten-5",
        });

        if (dataNascimento != "" && dataNascimento != null && capitalDivida != "") {
          await this.$store.dispatch("simulador_seguros", {
            dataNascimento,
            dataNascimentoSegundo,
            capitalDivida,
          });
        } else {
          let data = {
            iad: [],
            itp60: [],
            itp65: [],
          };

          this.$store.commit("SET_GLOBAL_VARIABLE", {
            var: "simulador",
            value: data,
          });
        }
      }
    },
    getValuesEsforco: function () {
      //Push para despesas e rendimentos:
      let despesas = 0;
      let rendimentos = 0;

      for (let i in this.rendimentosArray) {
        rendimentos += Number(this.prit[this.rendimentosArray[i]]);
        rendimentos += Number(
          this.sect[this.rendimentosArray[i]] != undefined
            ? this.sect[this.rendimentosArray[i]]
            : 0
        );
      }

      this.$store.commit("SET_GLOBAL_VARIABLE", {
        var: "rendimentos",
        value: rendimentos,
      });

      for (let i in this.despesasArray) {
        despesas += Number(this.prit[this.despesasArray[i]]);
        despesas += Number(
          this.sect[this.despesasArray[i]] != undefined
            ? this.sect[this.despesasArray[i]]
            : 0
        );
      }

      this.$store.commit("SET_GLOBAL_VARIABLE", {
        var: "despesas",
        value: despesas,
      });
    },
    emailTo: async function () {
      let email = this.lead.email;
      let subject = "Reorganiza - Contacto";
      let body = "";

      window.open("mailto:" + email + "?subject=" + subject + "&body=" + body);
    },
    getChildValues(content) {
      let { id, name, dataAniversario, contribuinte } = content;

      let index = this.childValues.findIndex((e) => e.id == id);
      if (index < 0) {
        this.childValues.push({
          id,
          name,
          dataAniversario,
          contribuinte,
        });
      } else {
        this.childValues[index] = {
          id,
          name,
          dataAniversario,
          contribuinte,
        };
      }
    },
    formatContentSeguros(array) {
      let mensalidade =
        this.seguros.spread_actual_seguradora == ""
          ? 0
          : Number(this.seguros.spread_actual_seguradora);

      let max = array.map((e) => mensalidade - Number(e.premio));
      max = Math.max(...max);

      return array.map((e) => ({
        name: e.seguradora,
        premio: e.premio,
        poupanca: Number(mensalidade) - Number(e.premio),
        principal: max == Number(mensalidade) - Number(e.premio),
      }));
    },
    async prepareComponent() {
      this.groupLeadDetails();
      this.typeLead();
      this.formatContent();
      await this.collectLeadHistory();
      await this.collectExtraLeadData()
      await this.collectLeadDocuments();
    },
  },
  created: async function () {
    Object.assign(this.$data, initialState());
    await this.prepareComponent();
  },
  computed: {
    completeLead() {
      let lead = this.lead;
      let items = ['prit_data','sect_data','operation_data','responsabilities','comercial_assessment','observations','owner','escritura','avaliacao','seguros','banco'];

      for(let i of items) {
        if(lead[i] == null || lead[i] == undefined) {
          if(typeof leadComplete[i] == 'string') lead[i] = leadComplete[i];
          else lead[i] = Object.assign({},leadComplete[i]);
        }
      }
      return lead;
    },
    valueICSDisabled() {
      let cond = [
        this.escritura.data_da_escritura == "",
        this.escritura.hora_da_escritura == "",
        this.escritura.banco_principal_escritura == "",
        this.escritura.agencia == "",
        this.escritura.local_da_escritura == "",
      ];

      return cond.includes(true);
    },
    numberChild() {
      return Number(this.$store.state.numberOfChild);
    },
    saudeSeguradora() {
      return this.$store.state.saudeSeguradora;
    },
    variablesSimulador: function () {
      let result = this.$store.state.simulador;

      let variables = [
        {
          title: "Morte + IAD",
          items: [],
        },
        {
          title: "Morte + ITP 65% | 66%",
          items: [],
        },
        {
          title: "Morte + ITP 60%",
          items: [],
        },
      ];

      if (Object.keys(result).length > 0) {
        variables[0].items = this.formatContentSeguros(result["iad"]);
        variables[1].items = this.formatContentSeguros(result["itp65"]);
        variables[2].items = this.formatContentSeguros(result["itp60"]);
      }

      return variables;
    },
    color: function () {
      return this.$store.state.colorBackgroundInput;
    },
  },
  watch: {
    lead: async function (newLead) {
      Object.assign(this.$data, initialState());
      this.lead = newLead;
      this.prepareComponent();
    },
    dialog: async function (newDialog) {
      if (newDialog == false) {
        this.tab = null;
      }
      this.dialog = newDialog;
    },
  },
};
</script>

<style scoped>
h1 {
  margin-left: 40px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}

h2 {
  margin-left: 40px;
  color: black;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  display: block;
}
.h2-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  margin-bottom: -6px;
}

.container {
  margin: 10px 0px;
  max-width: 100%;
}

.colunas {
  padding: 20px 0;
  margin-top: 10px;
}

.colunas-interiores {
  padding: 20px 40px;
}

.v-list-item {
  min-height: 10px;
  padding: 0;
  margin-bottom: 0 !important;
}

.v-list-item__content {
  padding: 8px 0;
}

.v-list-item__title {
  font-size: 14px;
}

h3 {
  font-size: 14px;
  margin-bottom: 10px;
}
.v-item-group {
  padding: 0 30px;
}
.v-list-item__icon {
  margin-right: 10px !important;
  margin-left: 20px;
}

.tabs-container {
  padding: 0px 40px;
  margin-top: 20px;
}

.callbacks-container {
  padding: 0px 40px;
  margin-top: 20px;
  padding-right: 20px;
}

.v-tabs-items {
  padding: 0;
  margin-top: 20px;
}

.inputs-container {
  margin: 0;
  padding: 0;
}

.timeline-date {
  font-size: 14px;
  padding-bottom: 5px !important;
}

.timeline-container {
  margin-top: 20px;
  padding-right: 10px;
}

.col-padding {
  padding: 0px 10px !important;
}

.h3-resumo {
  font-weight: normal;
}

.h2-resumo {
  margin-left: 0;
}

.legenda-input {
  width: 30px;
  height: 30px;
  border-radius: 16px;
  background-color: #ffcdd2;
}
.resumo {
  color: rgba(0, 0, 0, 0.87) !important;
}
.dependentes:not(:last-of-type) {
  margin-bottom: 8px !important;
}
</style>
   