<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        outlined
        v-model="name"
        :label="`Nome da Pessoa ${order}`"
        @change="getTheValue()"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-text-field
        outlined
        v-model="contribuinte"
        :label="`Contribuinte ${order}`"
        @change="getTheValue()"
      ></v-text-field>
    </v-col>
    <v-col cols="2">
      <v-text-field
        outlined
        v-model="dataAniversario"
        :label="`Data de Aniversário ${order}`"
        @change="getTheValue()"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "numberOfChild",
  props: ["order", "values"],
  emits: ["getvalue"],
  data() {
    return {
      dataAniversario: "",
      name: "",
      contribuinte: "",
    };
  },
  methods: {
    getTheValue() {
      this.$emit("getvalue", {
        id: this.order,
        name: this.name,
        dataAniversario: this.dataAniversario,
        contribuinte: this.contribuinte,
      });
    },
  },
  created() {
    this.dataAniversario = this.values.dataAniversario;
    this.name = this.values.name;
    this.contribuinte = this.values.contribuinte;
  },
};
</script>

<style>
</style>