var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#c4c22c","fab":"","x-small":"","dark":""},on:{"click":function($event){_vm.changeVisualBtn(false);
          _vm.formatContent();}}},on),[_c('v-icon',[_vm._v("create")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"#c4c22c"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.confirmClose(true)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title'),_c('v-spacer'),_c('v-toolbar-items')],1),_c('div',{staticClass:"reports"},[_c('h1',[_vm._v(" "+_vm._s(_vm.lead.current_step)+" - processo "+_vm._s(_vm.lead.lead_process_id)),_c('span',{staticClass:"h1-underline"})]),_c('div',{staticClass:"container"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":9}},[_c('v-card',{staticClass:"colunas"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('h2',[_vm._v(" Cliente - "+_vm._s(_vm.lead.nome)),_c('span',{staticClass:"h2-underline"})])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"color":"#009ddc","small":"","dark":"","fab":""},on:{"click":_vm.emailTo}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email")])],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('Calls',{attrs:{"resultados":_vm.radioElem,"lead":_vm.lead,"owner":_vm.owner.owner,"lead_process_id":_vm.lead.lead_process_id,"formatDate":_vm.formatDate},on:{"update-history":_vm.reloadData,"update-history-final":_vm.reloadDataFinal}})],1)],1),[_c('v-card',{staticClass:"tabs-container",attrs:{"flat":""}},[_c('v-tabs',{attrs:{"color":"#c4c22c"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(
                            item.tab == 'Owner' ? Number(_vm.userlevel) > 1 : true
                          ),expression:"\n                            item.tab == 'Owner' ? Number(userlevel) > 1 : true\n                          "}],key:item.tab,on:{"click":function($event){return _vm.setAttributesSeguros()}}},[_vm._v(" "+_vm._s(item.tab)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item.tab},[(
                              item.desc === 'obs' ||
                              item.desc === 'obs_ch' ||
                              item.desc === 'obs_ss'
                            )?_c('v-row',[_c('v-col',{staticClass:"col-padding",attrs:{"cols":12}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"inputs-container"},[_vm._l((item.content),function(text,i){return _c('div',{key:i},[_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"cols":text.cols}},[_c('h2',{staticClass:"ml-0"},[_vm._v(" "+_vm._s(text.title)),_c('span',{staticClass:"h2-underline"})])])],1),_c('v-row',{staticClass:"px-5"},_vm._l((text.content),function(el,j){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(el.value != ''),expression:"el.value != ''"}],key:j,staticClass:"colunas-interiores py-1",attrs:{"cols":el.cols}},[_c('div',{staticClass:"my-0 py-0 resumo"},[_c('p',{staticClass:"my-0 font-weight-bold text-capitalize subtitle-2"},[_vm._v(" "+_vm._s(el.title .replace(/___/g, "/") .replace(/__/g, "-") .replace(/_/g, " "))+" ")]),(el.title != 'dependentes__seguros')?[_c('p',{staticClass:"font-weight-light text-capitalize my-0 caption"},[_vm._v(" "+_vm._s(el.value)+" ")])]:_vm._l((el.value),function(val,k){return _c('p',{key:k,staticClass:"font-weight-light my-0 text-capitalize caption dependentes"},[_vm._v(" Nome: "+_vm._s(val.name)+" "),_c('br'),_vm._v(" Data nascimento: "+_vm._s(val.dataAniversario)+" "),_c('br'),_vm._v(" NIF: "+_vm._s(val.contribuinte)+" ")])})],2)])}),1)],1)}),_c('v-row',_vm._l((item.secondRow),function(text,i){return _c('v-col',{key:i,attrs:{"cols":text.cols}},[(text.type === 'textarea')?_c('v-textarea',{attrs:{"outlined":"","label":text.title,"value":text.value},on:{"change":function($event){return _vm.getTheValue({
                                            title: text.title,
                                            value: text.value,
                                            desc: 'obs',
                                          })}},model:{value:(text.value),callback:function ($$v) {_vm.$set(text, "value", $$v)},expression:"text.value"}}):_vm._e()],1)}),1),_c('v-spacer')],2)],1)],1)],1):_vm._e(),(item.desc === 'docs')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.docsHeaders,"items":_vm.documents,"loading-text":"A carregar..."},scopedSlots:_vm._u([{key:"item.view",fn:function(ref){
                                          var item = ref.item;
return [_c('v-btn',{attrs:{"color":"#c4c22c","small":"","dark":"","fab":"","href":item.url,"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"#c4c22c","dark":"","href":("https://app.reorganiza.pt/uploads/14e4e6afd220e4b720e5382c937170b04d3adb31442a56601486950374956ac3/" + (_vm.lead.lead_process_id)),"target":"_blank"}},[_c('v-icon',{staticClass:"mx-3",attrs:{"left":"","dark":""}},[_vm._v(" mdi-file-upload-outline ")]),_vm._v(" Upload de Documentos ")],1)],1)],1)],1)],1)],1)],1):(
                              item.desc != 'obs' &&
                              item.desc != 'obs_ch' &&
                              item.desc != 'obs_ss'
                            )?_c('div',[_c('Inputs',{attrs:{"item":item,"secondRow":false,"valueICSDisabled":_vm.valueICSDisabled,"lead":_vm.lead},on:{"getvalue":_vm.getTheValue,"action":_vm.actionBtn,"resp":_vm.updateResps,"ics":_vm.getValueICS}}),_vm._l((_vm.numberChild),function(i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.saudeSeguradora),expression:"saudeSeguradora"}],key:i},[(item.desc == 'seguros')?_c('numberOfChild',{attrs:{"order":i,"values":i - 1 < _vm.seguros.dependentes__seguros.length
                                    ? _vm.seguros.dependentes__seguros[i - 1]
                                    : {
                                        name: '',
                                        dataAniversario: '',
                                        contribuinte: '',
                                      }},on:{"getvalue":_vm.getChildValues}}):_vm._e()],1)}),_c('Inputs',{attrs:{"item":item,"secondRow":true,"valueICSDisabled":_vm.valueICSDisabled,"lead":_vm.lead},on:{"getvalue":_vm.getTheValue,"action":_vm.actionBtn,"resp":_vm.updateResps,"ics":_vm.getValueICS}})],2):_vm._e(),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"legenda-input"}),_c('p',{staticClass:"ml-3 pb-0 my-auto"},[_vm._v(" Campos com preenchimento prioritário ")])])])],1),_c('v-row',[(
                                item.desc == 'prit_ch' ||
                                item.desc == 'sect_ch'
                              )?_c('v-col',{attrs:{"cols":"10"}},[(_vm.lead.current_step_id == 12)?_c('TaxadeEsforco'):_vm._e()],1):_vm._e(),(
                                (item.desc == 'seguros' ||
                                  item.desc == 'prit_ss' ||
                                  item.desc == 'sect_ss') &&
                                _vm.lead.current_step_id == '35'
                              )?_c('v-col',{staticClass:"ma-0",attrs:{"cols":"10"}},[_c('Simulacoes',{attrs:{"dataNascimento":_vm.prit.data_nascimento,"dataNascimentoSegundo":_vm.sect.data_nascimento,"capitalDivida":_vm.seguros.capital_em_divida,"variablesSimulador":_vm.variablesSimulador}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":item.desc == 'prit_ch' ||
                                item.desc == 'sect_ch'
                                  ? 2
                                  : 12}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"x-large":"","color":"rgb(196, 194, 44)"},on:{"click":_vm.updateLeadData}},[_vm._v(" SALVAR "),_c('v-icon',{staticClass:"ml-2",attrs:{"right":"","dark":""}},[_vm._v(" mdi-content-save ")])],1)],1)])],1)],1)}),1)],1)]],2)],1),_c('v-col',{attrs:{"cols":3}},[_c('v-card',{staticClass:"colunas"},[_c('h2',[_vm._v("Próximo Contacto"),_c('span',{staticClass:"h2-underline"})]),(this.lead.callback_date == null)?_c('v-card',{staticClass:"callbacks-container",attrs:{"flat":""}},[_vm._v(" Nenhum contacto agendado ")]):_c('v-card',{staticClass:"callbacks-container",attrs:{"flat":""}},[_vm._v(" "+_vm._s(this.lead.callback_date)+" ")])],1),_c('v-card',{staticClass:"colunas"},[_c('h2',[_vm._v("Histórico"),_c('span',{staticClass:"h2-underline"})]),_c('v-timeline',{staticClass:"timeline-container",attrs:{"align-top":"","dense":""}},_vm._l((_vm.historico),function(hist,i){return _c('v-timeline-item',{key:i,attrs:{"fill-dot":"","right":"","small":"","color":"#999"}},[_c('v-card',{staticClass:"elevation-2"},[_c('v-card-title',{staticClass:"timeline-date"},[_vm._v(_vm._s(hist.data))]),_c('v-card-text',[_c('b',[_vm._v("Resultado:")]),_vm._v(" "+_vm._s(hist.resultado)+" "),_c('br'),_c('b',[_vm._v("Estado:")]),_vm._v(" "+_vm._s(hist.estado)+" ")])],1)],1)}),1)],1),_c('v-card',{staticClass:"colunas"},[_c('h2',[_vm._v("Outros dados"),_c('span',{staticClass:"h2-underline"})]),_c('p',{staticClass:"ml-10 mt-5"},[_vm._v(" Origem: "+_vm._s(_vm.extraInfo.origem_lp)+" "),_c('br'),_vm._v(" Consultor: "+_vm._s(_vm.extraInfo.consultor_lp)+" "),_c('br'),_vm._v(" URL: "+_vm._s(_vm.extraInfo.url)+" ")])])],1)],1)],1)],1)])],1),_c('OverlayAlert',{on:{"confirm":function($event){return _vm.confirmClose(false)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }