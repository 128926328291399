<template>
  <v-data-table
    :headers="headers"
    :items="dataTable"
    item-key="name"
    class="elevation-1 pt-5"
    :items-per-page="itemsPerPage"
    :hide-default-footer="hideDefaultFooter"
    :loading="loaderDataTable"
    loading-text="A carregar..."
    calculate-widths
  >
    <template v-slot:item.detalhes="{ item }" v-if="!input">
      <LeadDetail :lead="item.detalhes" />
    </template>
  </v-data-table>
</template>

<script>
import LeadDetail from "@/components/Lead_Detail";

export default {
  name: "SimpleTab",
  components: {
    LeadDetail,
  },
  props: {
    data: {
      type: Array,
      require: true,
    },
    input: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      require: true,
    },
    loaderDataTable: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    dataTable() {
      if (this.input) {
        let insertData = this.$store.state.insertData;

        return insertData.concat(this.data);
      } else return this.data;
    },
  },
};
</script>

<style>
</style>